<template>
	<div class="d-flex justify-content-between align-items-center flex-column h-100">
		<div class="d-flex flex-column gap-4 contact-container">
			<div class="card">
				<div class="">
					<font-awesome-icon icon="phone" size="lg" class="mr-2" />
					{{$t('contact-view.phone') }}
				</div>
				<div class="">
					<a href="tel:+36206152055">
						+36 20 615 20 55
					</a>
				</div>
			</div>

			<div class="card">
				<div class="">
					<font-awesome-icon icon="envelope" size="lg" class="mr-2" />
					{{$t('contact-view.email') }}
				</div>
				<div class="">
					<a href="mailto:support@pilotnet.hu">
						support@pilotnet.hu
					</a>
				</div>
			</div>

			<router-link
				:to="$i18n.locale == 'hu' ? 'privacy-statement-hu' : 'privacy-statement'"
				tag="button"
				class="btn-primary w-100 btn-main"
			>
				{{ $t('aboutUs.privacyStatement') }}
			</router-link>

			<router-link
				:to="$i18n.locale == 'hu' ? 'gtc-hu' : 'gtc'"
				tag="button"
				class="btn-primary w-100 btn-main"
			>
				{{ $t('aboutUs.termsAndConditions') }}
			</router-link>

			<div>

			</div>
		</div>
		<div class="text-center pt-2 px-2">
			{{ $t('aboutUs.allRightsReserved') }}
			<small class="d-block text-muted" @click="toggleTestMode">
				<span v-if="version">{{$t('aboutUs.version')}}: {{version || '-'}} |</span> {{$t('aboutUs.bundleVersion')}}: {{bundleVersion || '-'}}
				<span v-if="testMode" class="text-danger"> | TEST MODE</span>
			</small>

		</div>
	</div>
</template>

<script>
import versionConfig from "../../../version.config";

export default {
	name: 'ContactView',
	methods: {
		toggleTestMode() {
			if(!this.testModeToggler) {
				console.log('testModeToggler is not enabled');
				return
			}
			if(this.testModeTogglerTapCount < 3) {
				this.testModeTogglerTapCount++;
				console.log('testModeTogglerTapCount', this.testModeTogglerTapCount);
				return;
			}

			console.log("toggleTestMode");
			this.testModeTogglerTapCount = 0;
			this.testModeToggler = false

			if(this.testMode) {
				this.$emit( 'addMessage',{ messageKey: 'testMode.disabled', severity: 'info' })
				window.loadAppLink('r2gflightserver://test-mode?on=0');
				return;
			}
			this.$emit( 'addMessage',{ messageKey: 'testMode.enabled', severity: 'info' })

			window.loadAppLink('r2gflightserver://test-mode?on=1');
		}
	},
	data(){
		return {
			version: window.version,
			bundleVersion: versionConfig.bundleVersion,
			testModeToggler: false,
			testModeTogglerTapCount: 0,
		}
	},
	computed: {
		testMode() {
			return window.location.href.includes('testMode')
		}
	},
	mounted() {
		const userToken = this.$store.getters.loggedInUserToken;
		if(userToken) {
			this.testModeToggler = true;
		}
	},
}


</script>


<style scoped>

.btn-primary.btn-main {
	word-break: keep-all;
	text-align: center;
	padding: 0.5rem 1rem;
	border: none;
	border-radius: 0.5rem;
}


.card {
	padding: 1rem;
	border-radius: 0.5rem;
	border: 1px solid #dee2e6;
}

.contact-container {
	max-width: 400px;
	width: 100%;
}

.card.active {
	border-color: var(--primary);
}


</style>
